import React, {useState,useEffect} from 'react'
import Ticker from 'react-ticker'
import Grid from '@mui/material/Grid';
import moment from 'moment'
import Typography from '@mui/material/Typography';


const ScoreCard = ({game,renderGameView}) => {
  var team1Font = 'normal'
  var team2Font = 'normal'
  var team2FontColor = 'black'
  var team1FontColor = 'black'

  if(game['team1Result']==='WIN'){
    team1FontColor = '#d4000f'
    team1Font = 'bold'
  }
  if(game['team2Result']==='WIN'){
    team2FontColor = '#d4000f'
    team2Font = 'bold'
  }

  return(
    <Grid onClick={() => renderGameView(game)} style={{backgroundColor:'#f0f0f0',cursor:'pointer'}} container>


      <Grid item xs={3}>
      </Grid>
      <Grid style={{textAlign:'center',fontSize:12,fontWeight:'bold'}} item xs={6}>
        {moment(game['startTime']).format('MMMM DD')} - {moment(game['startTime']).format('hh:mm A')}
      </Grid>
      <Grid item xs={3}>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
      <Grid item xs={2}></Grid><Grid style={{whiteSpace:'nowrap',fontWeight:team1Font,fontSize:14,color:team1FontColor}} item xs={6}>{game['team1']}</Grid><Grid item xs={1}></Grid><Grid style={{whiteSpace:'nowrap',fontWeight:team1Font,fontSize:14,color:team1FontColor}} item xs={1}>{game['team1Score']}</Grid><Grid item xs={2}></Grid>
        </Grid>
      </Grid>


      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={2}></Grid><Grid style={{whiteSpace:'nowrap',fontWeight:team2Font,fontSize:14,color:team2FontColor}} item xs={6}>{game['team2']}</Grid><Grid item xs={1}></Grid><Grid style={{whiteSpace:'nowrap',fontWeight:team2Font,fontSize:14,color:team2FontColor}} item xs={1}>{game['team2Score']}</Grid><Grid item xs={2}></Grid>
        </Grid>
      </Grid>











    </Grid>
  )
}


const ScoresTicker = ({games,renderGameView}) => {



  return (
    <>
    {games[0] ? <Ticker
     offset='run-in'
     speed={15}
    >
        {({ index }) => (

            <>

            <ScoreCard renderGameView={renderGameView} game={games[index%games.length]}/>

            </>
        )}
    </Ticker> : null}
    </>
  );
}

export default ScoresTicker
