import React from 'react'
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import '../index.css'
import Button from '@mui/material/Button';
const compareFn = (a,b) => {
  if(a>b){
    return -1
  }
  else{
    return 1
  }
}

const StandingsTable = ({games,teamLists,isMobile}) => {

  const renderTableHeaders = (divisionName) => {
    return(
      <TableRow key={divisionName}>
      <TableCell sx={{color:'white'}} className='datagridheader' align='center'>Team</TableCell>
      {isMobile ? null :<TableCell sx={{color:'white'}} className='datagridheader' align='center'>GP</TableCell>}
      <TableCell sx={{color:'white'}} className='datagridheader' align='center'>W</TableCell>
      <TableCell sx={{color:'white'}} className='datagridheader'  align='center'>L</TableCell>
     <TableCell sx={{color:'white'}} className='datagridheader'  align='center'>T</TableCell>
        <TableCell sx={{color:'white'}} className='datagridheader'  align='center'>PTS</TableCell>
        {isMobile ? null :<TableCell sx={{color:'white'}} className='datagridheader'  align='center'>Win %</TableCell>}
      {isMobile ? null : <TableCell sx={{color:'white'}} className='datagridheader'  align='center'>PF</TableCell>}
      {isMobile ? null : <TableCell sx={{color:'white'}} className='datagridheader'  align='center'>PA</TableCell>}
      {isMobile ? null :<TableCell sx={{color:'white'}} className='datagridheader'  align='center'>+/-</TableCell>}
      </TableRow>

    )
  }


  const renderTableCells = (teamList) => {
    const teamRows = sumGameData()

    return(
      teamRows.map((teamRow) => {
        if(teamList.includes(teamRow.ID)){
          return(
            <TableRow
              key={teamRow.Team}>
              <TableCell sx={{color:'#d4000f',fontWeight:'bold'}} align='center'>{teamRow.Team}</TableCell>
              {  isMobile ? null : <TableCell  align='center'>{teamRow.WIN+teamRow.LOSS+teamRow.DRAW}</TableCell>}
              <TableCell  align='center'>{teamRow.WIN}</TableCell>
              <TableCell  align='center'>{teamRow.LOSS}</TableCell>
             <TableCell  align='center'>{teamRow.DRAW}</TableCell>
              <TableCell  align='center'>{teamRow.WIN*2+teamRow.DRAW*1}</TableCell>
              {isMobile ? null :<TableCell  align='center'>{(Math.round(teamRow.WIN/(teamRow.LOSS+teamRow.WIN+teamRow.DRAW)*1000)/1000).toFixed(3)}</TableCell>}
              {  isMobile ? null : <TableCell  align='center'>{teamRow.PF}</TableCell>}
              {  isMobile ? null : <TableCell  align='center'>{teamRow.PA}</TableCell>}

            {isMobile ? null :<TableCell  align='center'>{teamRow.PF-teamRow.PA}</TableCell>}
            </TableRow>
          )
        }
      }
      )
    )
  }

  const sumGameData = () => {
    const standings = {}

    games.map((game) => {

      //Create the object if it does not exis. Must be done for both teams
      if(standings[game.team1]){
      }
      else{
        standings[game.team1]={"WIN":0,"DRAW":0,"LOSS":0,"PF":0,"PA":0,"ID":game.team1Id}
      }
      if(standings[game.team2]){

        }
      else{
        standings[game.team2]={"WIN":0,"DRAW":0,"LOSS":0,"PF":0,"PA":0,"ID":game.team2Id}
      }

      //Determine who won
      var team1Result = game.team1Result
      var team2Result = game.team2Result



      // Now check if each team has had that result before and act accordingly
      if(team1Result === null || team2Result === null){
        return(null)
      }
      standings[game.team1][team1Result]+=1
      standings[game.team2][team2Result]+=1
      standings[game.team1]["PF"]+=game.team1Score
      standings[game.team2]["PF"]+=game.team2Score
      standings[game.team1]["PA"]+=game.team2Score
      standings[game.team2]["PA"]+=game.team1Score
    })
    const standingsArray = Object.keys(standings).map((key) => {
      standings[key]['Team'] = key
      return(
        standings[key]
      )
    })
    // This ranks the teams, and teams that have less total games played but the same amount of points will be ranked higher
    var sortedStandings = standingsArray.sort((a,b) => compareFn( a["WIN"]+a["DRAW"]-a["WIN"]-a["LOSS"]-a["DRAW"] ,b["WIN"]+b["DRAW"]-b["WIN"]-b["LOSS"]-b["DRAW"]  ))
    return(sortedStandings)
  }



  const renderTable = (teamList,divisionName) => {
    return(
      <React.Fragment key={divisionName}>

      <TableHead  style={{color:'white'}}>
        <TableRow>
          <TableCell className='raiseBox' sx={{padding:0.1}} colSpan={10}>
            <Grid className='raiseBox' sx={{backgroundColor:'#d4000f',height:25,border:1,borderColor:'#dadada'}} item xs={12}>
              <Typography style={{textAlign:'center',color:'#fff',fontSize:13,marginTop:2}}>
              {divisionName}
              </Typography>
            </Grid>
          </TableCell>
        </TableRow>
      </TableHead>



         <TableHead   style={{color:'white'}}>
              {renderTableHeaders(divisionName)}
         </TableHead>
         <TableBody >
           {renderTableCells(teamList)}
         </TableBody>

     </React.Fragment>
    )
  }

  const renderStandingsTables = () => {
    return teamLists.map((teamList,i) => {
      return renderTable(teamList[0],teamList[1])
    })


  }


  return(


    <TableContainer component={Paper}>
    <Table  size='small' aria-label="simple table">

    {renderStandingsTables()}
    </Table>
    </TableContainer>


  )
}

export default StandingsTable
