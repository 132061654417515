import axios from 'axios'

const public_api_key = '696770b245761b9dbb54080a1c674ab1'
const site_id = 'gtaflagfootball'


// So I have to manipulate the program ID dynamically so that I can access the different leagues (mens,womens,etc.)


const instance = axios.create({
  baseURL: `https://public.leagueapps.io/v1/sites/${site_id}/`
})





instance.interceptors.request.use(
  (config) => {

    config.headers['la-api-key'] = public_api_key
    return config;
  }

)

export default instance;
