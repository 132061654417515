import { DataGrid } from '@mui/x-data-grid';
import React,{useState,useEffect} from 'react'
import '../index.css'


const labels_humanized = {
  'BLKS':'Blk',
  'INT':'Int',
  'PASS_TD':'Pass TD',
  'COMP':'Comp %',
  'REC':'Rec',
  'TKL':'Flags',
  'REC_TD':'Rec TD',
  'info':'Player',
  'TeamName':'Team',
  'SACKS':"Sacked",
  'PASS_INT':'Pass Int',
  'CONVERT': 'Conv',
  'DEF_TD': 'Def TD',

}

const compareFn = (a,b) => {
  if(a==='info'){
    return -1
  }
  else if(a==='TeamName' && b!=='info'){
    return -1
  }
  else if(a==='PASS_TD' && !['info','TeamName'].includes(b) ){
    return -1
  }
  else if(a==='PASS_INT' && !['info','TeamName','PASS_TD'].includes(b) ){
    return -1
  }
  else if(a==='COMP' && !['info','TeamName','PASS_TD','PASS_INT'].includes(b) ){
    return -1
  }
  else if(a==='SACKS' && !['info','TeamName','PASS_TD','PASS_INT','COMP'].includes(b) ){
    return -1
  }
  else if(a==='REC' && !['info','TeamName','PASS_TD','PASS_INT','SACKS','COMP'].includes(b) ){
    return -1
  }
  else if(a==='REC_TD' && !['info','TeamName','PASS_TD','PASS_INT','SACKS','REC','COMP'].includes(b) ){
    return -1
  }
  else if(a==='TKL' && !['info','TeamName','PASS_TD','PASS_INT','SACKS','REC','REC_TD','COMP'].includes(b) ){
    return -1
  }
  else if(a==='INT' && !['info','TeamName','PASS_TD','PASS_INT','SACKS','REC','REC_TD','TKL','COMP'].includes(b) ){
    return -1
  }
  else if(a==='BLKS' && !['info','TeamName','PASS_TD','PASS_INT','SACKS','REC','REC_TD','TKL','INT','COMP'].includes(b) ){
    return -1
  }
  else if(a==='DEF_TD' && !['info','TeamName','PASS_TD','PASS_INT','SACKS','REC','REC_TD','TKL','INT','COMP','BLKS'].includes(b) ){
    return -1
  }

  else{
    return 1
  }
}


const DataTable = ({data,labels,mobileCols,statType,sortModel,handleSortModel,filterModel,heightRestriction}) => {
  const [columnVisibilityModel,setColumnVisibilityModel] = useState({
    'BLKS':true,
    'INT':true,
    'PASS_TD':true,
    'REC':true,
    'TKL':true,
    'REC_TD':true,
    'info':true,
    'TeamName':true,
    'SACKS':true,
    'PASS_INT':true,
    'COMP':true,
    'DEF_TD':true
  })




  useEffect(()=> {
    if(statType==='QB'){
      setColumnVisibilityModel({
        'BLKS':false,
        'INT':false,
        'PASS_TD':true,
        'REC':false,
        'TKL':false,
        'REC_TD':false,
        'info':true,
        'TeamName':true,
        'SACKS':true,
        'PASS_INT':true,
        'COMP':true,
        'DEF_TD':false
      })
    }
    else if(statType==='Offense'){
      setColumnVisibilityModel({
        'BLKS':false,
        'INT':false,
        'PASS_TD':false,
        'REC':true,
        'TKL':false,
        'REC_TD':true,
        'info':true,
        'TeamName':true,
        'SACKS':false,
        'PASS_INT':false,
        'COMP':false,
        'DEF_TD':false
      })
    }
    else if(statType==='Defense'){
      setColumnVisibilityModel({
        'BLKS':true,
        'INT':true,
        'PASS_TD':false,
        'REC':false,
        'TKL':true,
        'REC_TD':false,
        'info':true,
        'TeamName':true,
        'SACKS':false,
        'PASS_INT':false,
        'DEF_TD':true,
        'COMP':false,
      })
    }
    else{
      setColumnVisibilityModel({

      })
    }


  },[statType])




  const handleLabels = () => {
    labels.sort((a,b) => compareFn(a,b))
    const columns = labels.map((label) => {

      if(label==='info'){
        return(
          {
            field: label,
            headerName: labels_humanized[label],
            flex:1,
            width:120,
            minWidth:120,
            headerAlign:'center',
            align:'center',
            renderHeader:(params: GridColumnHeaderParams) =>{
              return(
                <div style={{fontWeight:'bold'}}>
                  {labels_humanized[label]}
                </div>
              )
            },
            cellClassName:'redText',
            headerClassName:'datagridheader'
          }

        )
      }
      else if(label==='TeamName'){
        return(
          {
            display:false,
            field: label,
            headerName: labels_humanized[label],
            flex:1,
            width:140,
            minWidth:140,
            headerAlign:'center',
            align:'center',
            renderHeader:(params: GridColumnHeaderParams) =>{
              return(
                <div style={{fontWeight:'bold'}}>
                  {labels_humanized[label]}
                </div>
              )
            },
            headerClassName:'datagridheader'
          }

        )
      }
      else if(label==='PASS_TD' || label==='REC_TD' || label==='PASS_INT' ){
        return(
          {
            sortingOrder: ['desc', 'asc', null],
            field: label,
            headerName: labels_humanized[label],
            flex:1,
            width:100,
            minWidth:100,
            headerAlign:'center',
            align:'center',
            renderHeader:(params: GridColumnHeaderParams) =>{
              return(
                <div style={{fontWeight:'bold'}}>
                  {labels_humanized[label]}
                </div>
              )
            },
            headerClassName:'datagridheader'
          }

        )
      }
      else{
        return(
          {
            sortingOrder: ['desc', 'asc', null],
            field: label,
            headerName: labels_humanized[label],
            width:100,
            minWidth:100,
            headerAlign:'center',
            align:'center',
            renderHeader:(params: GridColumnHeaderParams) =>{
              return(
                <div style={{fontWeight:'bold'}}>
                  {labels_humanized[label]}
                </div>
              )
            },
            headerClassName:'datagridheader'

          }
        )
      }
    })
    return columns
  }

  let gridStyle = { height: 400, width: '100%' }
  if(heightRestriction){
    gridStyle = { height:200 ,width: '100%' }
  }
  return(
    <div style={gridStyle}>
      <DataGrid

      sx={{'.MuiButtonBase-root':{
        color:'#383434'

      },
    '.MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '&.MuiDataGrid-root': {
      border: 'none',
    },
    fontSize:12,backgroundColor:'white'
  }}
      columnVisibilityModel={columnVisibilityModel}
      disableColumnMenu={true}
        rowHeight={25}
        checkboxSelection={false}
        rows={data}
        columns={handleLabels()}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        onSortModelChange={handleSortModel}
        filterModel={{
          items: filterModel,
        }}

      />
    </div>
  )

}


export default DataTable
