import React from 'react'
import Grid from '@mui/material/Grid';
import moment from 'moment'
import Selector from './Selector'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import '../index.css'


const Schedule = ({games,renderGameView}) => {



  const renderSchedule = () => {
    const gamesHolder = {}
    games.map((game) => {
      if(typeof gamesHolder[moment(game['startTime']).format('MMMM DD')] !== 'undefined'){
        gamesHolder[moment(game['startTime']).format('MMMM DD')].push(game)
      }
      else{
        gamesHolder[moment(game['startTime']).format('MMMM DD')] = [game]
      }
    })
    const gamesHolderKeys = Object.keys(gamesHolder)
    if(gamesHolderKeys.length===0){

      return(
        <div>
        No Schedule Available
      </div>)
    }
    return gamesHolderKeys.map((key) => {
      return (
        <Grid key={key} container>
          <Grid component={'h3'} sx={{textAlign:'left',fontWeight:'bold'}} item xs={12}>
            {key}

          </Grid>
          {gamesHolder[key].map((game)=>{
            let team1FontWeight = 'normal'
            let team2FontWeight = 'normal'
            let team1FontColor = 'black'
            let team2FontColor = 'black'

            if(game.team1Result === 'WIN'){
              team1FontWeight = 'bold'
              team1FontColor = '#d4000f'
            }
            if(game.team2Result === 'WIN'){
              team2FontWeight = 'bold'
              team2FontColor = '#d4000f'
            }

            return(
              <Grid onClick={() => renderGameView(game)} sx={{margin:2,cursor:'pointer'}} key={game.gameId} item xs={12}>
                <Card className='raiseBox' variant="outlined">
                  <Grid sx={{margin:1}} key={key} container>
                    <Grid sx={{margin:1}} item xs={12}>
                      {moment(game['startTime']).format('hh:mm A')} - {game.subLocationName}
                    </Grid>
                    <Grid item xs={1}>

                    </Grid>
                    <Grid sx={{color:team1FontColor,fontWeight:team1FontWeight}} item xs={3}>
                      {game.team1}
                    </Grid>
                    <Grid item xs={0.75}>

                    </Grid>
                    <Grid sx={{color:team1FontColor,fontWeight:team1FontWeight}} item xs={1}>
                      {game.team1Score}
                    </Grid>
                    <Grid item xs={0.5}>
                      @
                    </Grid>
                    <Grid sx={{color:team2FontColor,fontWeight:team2FontWeight}} item xs={1}>
                    {game.team2Score}
                    </Grid>
                    <Grid item xs={0.75}>

                    </Grid>
                    <Grid sx={{color:team2FontColor,fontWeight:team2FontWeight}}  item xs={3}>
                      {game.team2}
                    </Grid>
                    <Grid item xs={1}>

                    </Grid>
                    <Grid sx={{margin:1}} item xs={12}>
                      {game.locationName}
                    </Grid>
                    {game.team1Result !== null ? <Grid sx={{margin:1,fontWeight:'bold'}} item xs={12}>
                      Click for Game Stats
                    </Grid> : null}


                  </Grid>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      )
    })

  }

  return(
    <div>
      {renderSchedule()}
    </div>
  )
}
export default Schedule
