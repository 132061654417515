import React, {useState} from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Selector = ({options,selected,handleLeagueChange,changeSort}) => {



  const handleChange = (e) => {
    if(changeSort){
      changeSort(e.target.value)
    }
    handleLeagueChange(e.target.value)
  }

  const renderOptions = () => {
    if(typeof options === 'undefined' ){
      return <MenuItem key='None' value='None'>None</MenuItem>
    }
    return options.map((option) => {
      return (
        <MenuItem key={option['key']} value={option['key']}>{option['label']}</MenuItem>
      )
    })
  }


  return(
    <FormControl fullWidth>

      <Select

        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selected}

        onChange={handleChange}

      >
        {renderOptions()}
      </Select>
    </FormControl>
  )
}

export default Selector
